import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

import Publications, {
  Props as PublicationsProps
} from 'components/in-print/Publications';

interface Props {
  data: PublicationsProps;
}

const DepartmentContent: React.FC<Props> = (props) => {
  const { publications } = props.data;

  return (
    <Layout departmentSlug="in-print" title="In Print">
      <Core.ContentWrapper>
        <Core.Flag>Published</Core.Flag>
        <Publications publications={publications} />
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    publications: allFile(
      filter: {
        relativePath: { regex: $directory }
        name: { eq: "in-print-publications" }
      }
    ) {
      ...Publications
    }
  }
`;

export default DepartmentContent;
